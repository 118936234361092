<template>
  <div id="app">
    <NavCustom></NavCustom>
    <div id="fb-root"></div>
    <router-view/>
    <FooterCustom></FooterCustom>
    <div class="fb-customerchat"
         attribution="install_email"
         attribution_version="biz_inbox"
         page_id="784997661847539">
    </div>
  </div>
</template>

<style lang="scss">
@import "~bulma/sass/utilities/_all";

$primary: #1a2b4c;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);

$gold: #aa8d31;
$gold-light: findLightColor($gold);
$gold-dark: findDarkColor($gold);
$gold-invert: findColorInvert($gold);

$yellow: #c2a756;
$yellow-light: findLightColor($yellow);
$yellow-dark: findDarkColor($yellow);
$yellow-invert: findColorInvert($yellow);

$green: #96E8BC;
$green-light: findLightColor($green);
$green-dark: findDarkColor($green);
$green-invert: findColorInvert($green);

$link: $gold;
$link-invert: findColorInvert($link);
$link-dark: findDarkColor($link);
$link-light: findLightColor($link);
$link-focus-border: $link;

$white: #ffffff;
$white-invert: findColorInvert($white);
$white-dark: findDarkColor($white);
$white-light: findLightColor($white);

$custom-colors: null !default;
$custom-shades: null !default;

$colors: mergeColorMaps(
  (
    "primary": (
      $primary,
      $primary-invert,
      $primary-light,
      $primary-dark,
    ),
    "gold": (
      $gold,
      $gold-invert,
      $gold-light,
      $gold-dark,
    ),
    "yellow": (
      $yellow,
      $yellow-invert,
      $yellow-light,
      $yellow-dark,
    ),
    "green": (
      $green,
      $green-invert,
      $green-light,
      $green-dark,
    ),
    "link": (
      $link,
      $link-invert,
      $link-light,
      $link-dark,
    ),
    "white": (
        $white,
        $white-invert,
        $white-light,
        $white-dark,
    )
  ),
  $custom-colors
);

@import url('https://fonts.googleapis.com/css2?family=Amiko&family=EB+Garamond:ital@0;1&display=swap');

#app, .button {
  font-family: 'Amiko', sans-serif !important;
}

.title {
  font-family: 'EB Garamond', serif;
  font-style: italic;
}
$title-color: $primary;
$title-weight: 400;

.tag {
  font-size: 10px !important;
}

$navbar-item-img-max-height: 48px;

@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>

<script>
import NavCustom from "./components/NavCustom";
import FooterCustom from "./components/FooterCustom";
export default {
  components: {FooterCustom, NavCustom},
  mounted() {
    window.fbAsyncInit = function() {
      FB.init({
        xfbml            : true,
        version          : 'v12.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }
}
</script>
