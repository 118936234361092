<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <img src="../assets/compass.png">
      <p>Adhika: Ateneo Law School Career Fair 2022</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterCustom"
}
</script>

<style scoped>
.footer {
  font-family: "EB Garamond", serif;
}
</style>